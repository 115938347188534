import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Stack, Typography, Drawer, Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { PRIMARY } from '../../../theme/utils/color';
import { PageManagerComponent, ContentBox } from './PageManagerComponent';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import useLoading, { setLoading } from '../../../features/useLoading';

import { createHttp } from '../../../api/http';
import { useFormation } from '../../../services/useFormation';
import { AuthStorage } from '../../../utils/AuthStorage';
import { images } from '../../../constants/staticImages';
import { toast } from 'react-toastify';
import moment from 'moment';
import { momentFr } from '../../../utils/moment-local';

const convertDifficulty = (difficulty) => {
	return ['Facile', 'Moyen', 'Difficile'][difficulty];
};

const convertDuration = (formationDuration) => {
	const duration = formationDuration?.split(':');
	return `${duration[0]}h${duration[1]}min`;
};

export const COURSE_SIDEBAR = 500;

export const CourseComponent = () => {
	const navigate = useNavigate();

	const momentJs = momentFr(moment);

	const currentUser = AuthStorage.isAuth()?.user;
	const http = createHttp();
	const { courseName: courseId } = useParams();
	const dataCourse = {
		courseLevel: 0, // 0 - Facile | 1 - Normal | 2 - Difficile,
		author: 'MAPIM Formation',
		category: 'Loi Alur'
	};
	const [currentFormation, setCurrentFormation] = useState(null);
	const [isAlreadyStarted, setIsAlreadyStarted] = useState(false);
	const [startedFormation, setStartedFormation] = useState([]);
	const navigateTo = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setLoading(false));
		const fetchFormation = async (formationId) => {
			if (!currentFormation) {
				dispatch(setLoading(true));
				const res = await http.get(`/formation/${formationId}`);
				console.log(res.data);
				setCurrentFormation(res.data);
				const resultat = res.data.users_taking.filter(
					(user) => user.user_id === currentUser?.id
				);
				setStartedFormation(resultat[0]);
				if (resultat.length > 0) setIsAlreadyStarted(true);
				dispatch(setLoading(false));
			}
		};
		fetchFormation(courseId);
	}, [currentFormation]);

	const handleSubscription = async () => {
		await useFormation
			.startFormation(currentFormation?.uuid)
			.then(() => {
				navigate(`/dashboard/courses/${currentFormation?.id}/0`);
			})
			.catch(() => {
				toast.warning(
					"Vous n'avez plus d'heure disponible pour suivre cette formation, veuillez acheter un nouveau plan pour continuer"
				);
			});
	};

	return (
		currentFormation && (
			<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						bgcolor: 'background.default',
						padding: '32px'
					}}>
					<Typography sx={{ marginBottom: '30px' }} variant={'h3'}>
						{currentFormation?.title}
					</Typography>
					<Box
						component={'img'}
						alt='courses-image'
						sx={{ display: { lg: 'none', xs: 'inherit' } }}
						width={'100%'}
						src={images(currentFormation?.photo)}
						padding={1}
					/>
					<Box
						sx={{
							width: '100%',
							padding: '0 16px',
							marginBottom: '8px',
							maxWidth: 896
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								borderBottom: '2px solid ' + PRIMARY.green,
								paddingBottom: '12px'
							}}>
							<Typography>
								<strong>Par</strong> {dataCourse.author}
							</Typography>
							<Typography>
								<strong>Niveau du cours :</strong>{' '}
								{convertDifficulty(dataCourse.courseLevel)}
							</Typography>
							<Typography>
								<strong>Partager :</strong>
								{currentFormation?.uuid}
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								paddingTop: '14px'
							}}>
							<Stack>
								<Typography sx={{ fontWeight: 'bold' }}>Durée</Typography>
								<Typography>
									{convertDuration(currentFormation?.duration)}
								</Typography>
							</Stack>
							<Stack>
								<Typography sx={{ fontWeight: 'bold' }}>
									Dernière mise à jour
								</Typography>
								<Typography>
									{momentJs(currentFormation?.updated_at).local('fr').fromNow()}
								</Typography>
							</Stack>
						</Box>
					</Box>

					<Typography sx={{ margin: '20px 0' }} variant={'h4'}>
						Description
					</Typography>
					{parse(currentFormation?.description)}

					<Typography
						sx={{ marginBottom: '15px', marginTop: '15px' }}
						variant={'h4'}>
						Que vais-je apprendre ?
					</Typography>
					<Box sx={{ marginLeft: '24px' }}>
						{parse(currentFormation?.to_learn)}
					</Box>

					<PageManagerComponent
						items={[
							{
								name: 'Page du cours',
								element: (
									<Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignContent: 'center',
												alignItems: 'center',
												marginBottom: '20px'
											}}>
											<Typography variant={'h4'}>
												Sujet pour ce cours
											</Typography>
											<Box sx={{ display: 'flex', gap: '10px' }}>
												<Typography>
													{currentFormation?.chapters.length} chapitre(s)
												</Typography>
												<Typography>
													{convertDuration(currentFormation?.duration)}
												</Typography>
											</Box>
										</Box>
										{currentFormation?.chapters.map((chapter, i) => {
											return (
												<ContentBox
													courseDetail={chapter.lessons}
													chapter={chapter.title}
													key={i}
												/>
											);
										})}
									</Box>
								)
							},
							{
								name: 'Q&R',
								element: <h1>Question 1 | Réponse</h1>
							}
						]}
					/>
				</Box>
				<Box
					sx={{
						width: COURSE_SIDEBAR,
						flexShrink: 0,
						alignContent: 'center',
						'& .MuiDrawer-paper': {
							width: COURSE_SIDEBAR,
							boxSizing: 'border-box',
							height: 'unset'
						}
					}}
					variant='permanent'
					anchor='right'>
					<Box
						component={'img'}
						alt='courses-image'
						sx={{ display: { xs: 'none' } }}
						width={'100%'}
						src={images(currentFormation?.photo)}
						padding={1}
					/>
					{!AuthStorage.isAuth() && (
						<Button
							onClick={() => navigateTo('/auth/login')}
							sx={{
								padding: '5px',
								borderRadius: '5px',
								backgroundColor: PRIMARY.green,
								color: 'white',
								width: '196px',
								margin: '5px auto 0 auto',
								'&:hover': {
									color: 'white',
									backgroundColor: PRIMARY.green
								}
							}}>
							S'inscrire
						</Button>
					)}
					{!isAlreadyStarted && AuthStorage.isAuth() && (
						<Button
							onClick={handleSubscription}
							sx={{
								padding: '5px',
								borderRadius: '5px',
								backgroundColor: PRIMARY.green,
								color: 'white',
								width: '196px',
								margin: '5px auto 0 auto',
								'&:hover': {
									color: 'white',
									backgroundColor: PRIMARY.green
								}
							}}>
							Commencer le cours
						</Button>
					)}
					{isAlreadyStarted && AuthStorage.isAuth() && (
						<Link
							to={`/dashboard/courses/${currentFormation?.id}/${startedFormation.current_done}`}
							style={{
								textDecoration: 'none',
								textAlign: 'center',
								padding: '5px',
								borderRadius: '5px',
								backgroundColor: PRIMARY.green,
								color: 'white',
								width: '196px',
								margin: '5px auto 0 auto',
								'&:hover': {
									color: 'white',
									backgroundColor: PRIMARY.green
								}
							}}>
							Poursuivre le cours
						</Link>
					)}

					<Typography
						sx={{ margin: '50px 0 5px 10px', fontWeight: 'bold' }}
						variant={'h6'}>
						Ressources incluses
					</Typography>
					<Box sx={{ marginLeft: '32px' }}>
						<ul>
							{currentFormation?.included?.map((file, index) => {
								return (
									<li key={index}>
										<a
											href={`${process.env.REACT_APP_BASE_API_URL}/included/download/${file.file_name}`}>
											{file.file_name}
										</a>
									</li>
								);
							})}
						</ul>
					</Box>

					<Typography
						sx={{ margin: '70px 0 5px 10px', fontWeight: 'bold' }}
						variant={'h6'}>
						Étiquettes
					</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							gap: '10px',
							flexWrap: 'wrap',
							padding: '0 16px'
						}}>
						{currentFormation?.labels.split('|').map((tag, i) => {
							return (
								<Typography
									key={i}
									sx={{
										fontSize: 16,
										border: '1px solid ' + PRIMARY.green,
										padding: '2px 15px',
										borderRadius: '10px'
									}}>
									{tag}
								</Typography>
							);
						})}
					</Box>

					<Typography
						sx={{ margin: '70px 0 5px 10px', fontWeight: 'bold' }}
						variant={'h6'}>
						Public ciblé
					</Typography>
					<Box sx={{ marginLeft: '32px' }}>
						<Box
							sx={{
								display: 'flex',
								gap: '16px',
								alignItems: 'center',
								marginTop: '6px'
							}}>
							{parse(currentFormation?.target_audience)}
						</Box>
					</Box>
				</Box>
			</Box>
		)
	);
};
